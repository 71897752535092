import { urlConfig } from 'config/url.config'
import { memo } from 'react'
import { useIsAuthenticated } from 'react-auth-kit'
import { Navigate } from 'react-router-dom'

interface RequireAuthProps extends IChildren {
    loginPath?: string
}
export const RequireAuth: React.FC<RequireAuthProps> = memo(({ loginPath = urlConfig.pages.login.href, children }) => {
    const isAuth = useIsAuthenticated()()

    return isAuth ? children : <Navigate to={loginPath} replace />
})
RequireAuth.displayName = RequireAuth.name
