import { AdspaceTypeEnum, useCreateAdspaceMutation } from '_graphql/graphql'
import { LoadingButton } from 'components/loading-button'
import {
    AlertDialog,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { defaultIconProps } from 'config/icons.config'
import { mapConfig } from 'config/map.config'
import { PackagePlus } from 'lucide-react'
import { adspaceToasts } from 'modules/adspace/constants/adspace-toasts.constants'
import { ALL_ADSPACES_QUERY } from 'modules/adspace/queries/adspace-queries'
import { memo, useCallback, useState } from 'react'

interface AdspaceCreateConfirmableProps extends React.ComponentProps<typeof Button> {}
export const AdspaceCreateConfirmable: React.FC<AdspaceCreateConfirmableProps> = memo(({ ...props }) => {
    const [createAdspaceMutation] = useCreateAdspaceMutation({
        refetchQueries: [ALL_ADSPACES_QUERY],
    })

    const [open, setOpen] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const onSubmit = useCallback(async () => {
        try {
            setLoading(true)
            const res = await createAdspaceMutation({
                variables: {
                    data: {
                        number: 0,
                        type: AdspaceTypeEnum.Billboard,
                        lat: mapConfig.defaultLat,
                        lng: mapConfig.defaultLng,
                        sides: [],
                    },
                },
            })
            if ((!!res.errors?.length && res.errors.length > 0) || !res.data?.createAdspace) {
                throw new Error(`Response error: ${JSON.stringify(res.errors)}`)
            }
            adspaceToasts.create.success()
            setOpen(false)
        } catch (error) {
            console.error(error)
            adspaceToasts.create.error()
        } finally {
            setLoading(false)
        }
    }, [createAdspaceMutation])

    return (
        <AlertDialog open={open} onOpenChange={setOpen}>
            <AlertDialogTrigger asChild>
                <Button variant="outline" size="icon" {...props}>
                    <PackagePlus {...defaultIconProps} />
                </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Ви справді хочете додати нову площину?</AlertDialogTitle>
                    <AlertDialogDescription>Її можна буде відредагувати або видалити пізніше</AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>Скасувати</AlertDialogCancel>
                    <LoadingButton loading={loading} onClick={onSubmit}>
                        Додати
                    </LoadingButton>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
})
AdspaceCreateConfirmable.displayName = AdspaceCreateConfirmable.name
