import { AdspaceSideEntity } from '_graphql/graphql'
import { AdSpacesPieChart } from 'modules/adspace/components/adspace-pie-chart'
import { memo } from 'react'

interface AdspaceChartsStatisticsProps {
    adspaces: Array<{
        sides: Pick<AdspaceSideEntity, 'busyTo'>[]
    }>
}
export const AdspaceChartsStatistics: React.FC<AdspaceChartsStatisticsProps> = memo(({ adspaces }) => {
    return <AdSpacesPieChart data={adspaces} />
})
AdspaceChartsStatistics.displayName = AdspaceChartsStatistics.name
