import { urlConfig } from 'config/url.config'
import { memo, useMemo } from 'react'
import { Link } from 'react-router-dom'

interface FooterProps {}
export const Footer: React.FC<FooterProps> = memo(() => {
    const currentYear = useMemo(() => new Date().getFullYear(), [])

    return (
        <footer className="bg-background/90 text-muted-foreground mt-auto w-full border-t py-4 text-[0.5rem] backdrop-blur-sm sm:text-xs">
            <div className="container flex h-full items-center justify-between gap-1 text-center sm:flex-row">
                <p className="text-left">
                    Advertize/admin. Створено для{' '}
                    <Link to={urlConfig.WEBSITE_URL} className="font-semibold underline underline-offset-4">
                        {urlConfig.WEBSITE_URL?.replace(/(http:\/\/)|(https:\/\/)/g, '')}
                    </Link>
                    .
                </p>
                <p className="text-right">Advertize | BravoBoard | РА Браво © {currentYear}</p>
            </div>
        </footer>
    )
})
Footer.displayName = Footer.name
