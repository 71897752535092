import { AdspaceSideEntity } from '_graphql/graphql'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import { defaultIconProps } from 'config/icons.config'
import { CheckCircle, CircleDashed, CircleSlash2, Database } from 'lucide-react'
import { memo, useMemo } from 'react'
import { cn } from 'utils/cn'

interface AdspaceNumberStatisticsProps extends React.ComponentProps<'div'> {
    adspaces: Array<{
        sides: Pick<AdspaceSideEntity, 'busyTo'>[]
    }>
}
export const AdspaceNumberStatistics: React.FC<AdspaceNumberStatisticsProps> = memo(
    ({ adspaces, className, children, ...props }) => {
        const total = useMemo(() => adspaces.length, [adspaces.length])
        const busy = useMemo(() => adspaces.filter(adspace => adspace.sides.every(side => side.busyTo)).length, [adspaces])
        const partialBusy = useMemo(() => adspaces.filter(adspace => adspace.sides.some(side => side.busyTo)).length, [adspaces])
        const free = useMemo(() => adspaces.filter(adspace => adspace.sides.every(side => !side.busyTo)).length, [adspaces])

        const data = useMemo(
            () => [
                {
                    title: 'Всього площин',
                    value: total,
                    icon: (props: React.ComponentProps<typeof Database>) => <Database {...props} />,
                },
                {
                    title: 'Зайняті',
                    value: busy,
                    icon: (props: React.ComponentProps<typeof CircleSlash2>) => <CircleSlash2 {...props} />,
                },
                {
                    title: 'Частково зайняті',
                    value: partialBusy,
                    icon: (props: React.ComponentProps<typeof CircleDashed>) => <CircleDashed {...props} />,
                },
                {
                    title: 'Вільні',
                    value: free,
                    icon: (props: React.ComponentProps<typeof CheckCircle>) => <CheckCircle {...props} />,
                },
            ],
            [busy, free, partialBusy, total],
        )

        return (
            <div {...props} className={cn('grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-4', className)}>
                {data.map(item => (
                    <Card key={item.title} className="flex-1">
                        <CardHeader>
                            <CardTitle className="text-sm">{item.title}</CardTitle>
                        </CardHeader>
                        <CardContent className="flex items-center justify-between">
                            <h1 className="text-3xl">{item.value}</h1>
                            <item.icon {...defaultIconProps} className="text-muted-foreground" />
                        </CardContent>
                    </Card>
                ))}
            </div>
        )
    },
)
AdspaceNumberStatistics.displayName = AdspaceNumberStatistics.name
