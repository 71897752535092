import { AdspaceEntity } from '_graphql/graphql'
import { toast } from 'components/ui/use-toast'

export const adspaceToasts = {
    create: {
        success: () =>
            toast({
                title: 'Успіх!',
                description: `Площину додано.`,
            }),
        error: () =>
            toast({
                title: 'Виникла помилка',
                description: 'Щось пішло не так під час додавання площини. Спробуйте ще раз пізніше.',
                variant: 'destructive',
            }),
    },

    update: {
        success: (id: AdspaceEntity['id'] | AdspaceEntity['number']) =>
            toast({
                title: 'Успіх!',
                description: `Площину ${id} збережено.`,
            }),
        error: () =>
            toast({
                title: 'Виникла помилка',
                description: 'Щось пішло не так під час оновлення площини. Спробуйте ще раз пізніше.',
                variant: 'destructive',
            }),
    },

    delete: {
        success: (id: AdspaceEntity['id'] | AdspaceEntity['number']) =>
            toast({
                title: 'Успіх!',
                description: `Площину ${id} видалено.`,
            }),
        error: () =>
            toast({
                title: 'Виникла помилка',
                description: 'Щось пішло не так під час видалення площини. Спробуйте ще раз пізніше.',
                variant: 'destructive',
            }),
    },

    copyLink: {
        success: (id: AdspaceEntity['id'] | AdspaceEntity['number']) =>
            toast({
                title: `Посилання на площину ${id} скопійовано!`,
            }),
        error: () =>
            toast({
                title: 'Виникла помилка',
                description: 'Щось пішло не так під час копіювання посилання. Спробуйте ще раз пізніше.',
            }),
    },
}
