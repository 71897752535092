import { ApolloProvider } from '@apollo/client'
import { App } from 'app'
import { gqlClient } from 'config/graphql.config'
import { AuthProvider } from 'modules/user/components/hoc/auth-provider'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import 'styles/styles.css'
import 'styles/tailwind.css'

const element = document.getElementById('root') as HTMLElement
const root = createRoot(element)

root.render(
    <AuthProvider>
        <ApolloProvider client={gqlClient}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ApolloProvider>
    </AuthProvider>,
)
