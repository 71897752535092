import { AdspaceEntity, useDeleteAdspaceMutation } from '_graphql/graphql'
import { LoadingButton } from 'components/loading-button'
import {
    AlertDialog,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { adspaceToasts } from 'modules/adspace/constants/adspace-toasts.constants'
import { ALL_ADSPACES_QUERY } from 'modules/adspace/queries/adspace-queries'
import React, { memo, useCallback, useState } from 'react'
import { deleteImageFromStorage } from 'utils/firebase-utils'

interface AdspaceDeleteConfirmableProps extends React.ComponentProps<typeof AlertDialogTrigger> {
    adspace: AdspaceEntity
}
export const AdspaceDeleteConfirmable: React.FC<AdspaceDeleteConfirmableProps> = memo(({ adspace, ...props }) => {
    const [deleteAdspaceMutation] = useDeleteAdspaceMutation({
        refetchQueries: [ALL_ADSPACES_QUERY],
    })

    const [open, setOpen] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const onSubmit = useCallback(async () => {
        try {
            setLoading(true)
            adspace.sides.forEach(side => deleteImageFromStorage(side.image))
            const res = await deleteAdspaceMutation({
                variables: {
                    id: adspace.id,
                },
            })
            if ((!!res.errors?.length && res.errors.length > 0) || !res.data?.deleteAdspace) {
                throw new Error(`Response error: ${JSON.stringify(res.errors)}`)
            }
            adspaceToasts.delete.success(res.data.deleteAdspace.number)
            setOpen(false)
        } catch (error) {
            console.error(error)
            adspaceToasts.delete.error()
        } finally {
            setLoading(false)
        }
    }, [adspace.id, adspace.sides, deleteAdspaceMutation])

    return (
        <AlertDialog open={open} onOpenChange={setOpen}>
            <AlertDialogTrigger {...props} />
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Ви справді хочете видалити площину?</AlertDialogTitle>
                    <AlertDialogDescription>Цю дію не можна відмінити</AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>Скасувати</AlertDialogCancel>
                    <LoadingButton variant="destructive" loading={loading} onClick={onSubmit}>
                        Видалити
                    </LoadingButton>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
})
AdspaceDeleteConfirmable.displayName = AdspaceDeleteConfirmable.name
