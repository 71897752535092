import { AdspaceTypeEnum } from '_graphql/graphql'
import { SVGProps, memo, useMemo } from 'react'
import { Marker } from 'react-map-gl'
import { cn } from 'utils/cn'

interface SvgFreeProps extends Partial<SVGProps<SVGSVGElement>> {}
const SvgFree: React.FC<SvgFreeProps> = memo(({ ...props }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" {...props}>
            <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z" />
        </svg>
    )
})
SvgFree.displayName = 'SvgFree'

//

interface SvgBusyProps extends Partial<SVGProps<SVGSVGElement>> {}
const SvgBusy: React.FC<SvgBusyProps> = memo(({ ...props }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" {...props}>
            <path d="M192 0C86.4 0 0 86.4 0 192c0 76.8 25.6 99.2 172.8 310.4 9.6 12.8 28.8 12.8 38.4 0C358.4 291.2 384 268.8 384 192 384 86.4 297.6 0 192 0zm84.85 242.91c6.25 6.25 6.25 16.38 0 22.63l-11.31 11.31c-6.25 6.25-16.38 6.25-22.63 0L192 225.94l-50.91 50.91c-6.25 6.25-16.38 6.25-22.63 0l-11.31-11.31c-6.25-6.25-6.25-16.38 0-22.63L158.06 192l-50.91-50.91c-6.25-6.25-6.25-16.38 0-22.63l11.31-11.31c6.25-6.25 16.38-6.25 22.63 0L192 158.06l50.91-50.91c6.25-6.25 16.38-6.25 22.63 0l11.31 11.31c6.25 6.25 6.25 16.38 0 22.63L225.94 192l50.91 50.91z" />
        </svg>
    )
})
SvgBusy.displayName = 'SvgFree'

//

interface SvgMarkerProps extends SvgFreeProps, SvgBusyProps {
    isBusy: boolean
    type: AdspaceTypeEnum
}
const SvgMarker: React.FC<SvgMarkerProps> = memo(({ isBusy, type, className, ...props }) => {
    const defaultProps = useMemo(
        () => ({
            className: cn(
                'w-9 h-9 fill-red-600 drop-shadow outline-none',
                type === AdspaceTypeEnum.Arka && 'fill-blue-600',
                isBusy && 'opacity-70',
                className,
            ),
            ...props,
        }),
        [className, isBusy, props, type],
    )

    return isBusy ? <SvgBusy {...defaultProps} /> : <SvgFree {...defaultProps} />
})
SvgMarker.displayName = 'SvgMarker'

//

interface MapMarkerProps extends SvgMarkerProps {
    lat: number
    lng: number
    type: AdspaceTypeEnum
    isBusy: boolean
}
export const MapMarker: React.FC<MapMarkerProps> = memo(({ lat, lng, type, isBusy, className, ...props }) => {
    return (
        <Marker latitude={lat} longitude={lng}>
            <SvgMarker
                isBusy={isBusy}
                type={type}
                {...props}
                className={cn('animate-in fade-in-0 zoom-in-50 transition-all duration-300', className)}
            />
        </Marker>
    )
})
MapMarker.displayName = 'MapMarker'
