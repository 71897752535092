type GetFallbackLetters = (word: string, char?: string) => string
export const getFallbackLetters: GetFallbackLetters = (word = '', char = ' ') => {
    const firstLetters = word
        .split(char)
        .map(word => word[0])
        .join('')
        .slice(0, 2)
    if (firstLetters.length < 2) {
        return word.slice(0, 2)
    }
    return firstLetters
}
