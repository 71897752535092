import { AdspaceTypeEnum } from '_graphql/graphql'
import { z } from 'zod'

const MAX_FILE_SIZE = 10 * 1024 * 1024 // 10 MB

export const AdspaceUpdateSchema = z.object({
    number: z.number().nonnegative().int().min(0),
    lat: z.number().min(-90).max(90),
    lng: z.number().min(-180).max(180),
    type: z.nativeEnum(AdspaceTypeEnum),
    sides: z.array(
        z.object({
            busyTo: z.string(),
            image: z.string(),
            text: z.string(),
            //
            deleteImage: z.boolean().optional(),
            file: z
                .instanceof(File)
                .refine(file => file.size > 1, 'Файл занадто малий')
                .refine(file => file.size < MAX_FILE_SIZE, 'Файл не може бути більшим за 5 Мб')
                .refine(file => file.type.includes('image/'), 'Файл не є зображенням')
                .optional(),
        }),
    ),
})

export type AdspaceUpdateSchemaType = z.infer<typeof AdspaceUpdateSchema>
