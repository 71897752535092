import { urlConfig } from 'config/url.config'
import { AuthLayout } from 'modules/user/components/layouts/auth.layout'
import { LoginPage } from 'pages/login-page'
import { MainPage } from 'pages/main-page'
import { MapPage } from 'pages/map-page'
import { NotFoundPage } from 'pages/not-found.page'
import { OrdersPage } from 'pages/orders-page'
import { memo } from 'react'
import { useRoutes } from 'react-router-dom'

interface RoutesProps {}
export const Routes: React.FC<RoutesProps> = memo(() => {
    return useRoutes([
        // auth required
        {
            path: urlConfig.pages.main.href,
            element: <AuthLayout />,
            children: [
                {
                    index: true,
                    element: <MainPage />,
                },
                {
                    path: urlConfig.pages.map.href,
                    element: <MapPage />,
                },
                {
                    path: urlConfig.pages.orders.href,
                    element: <OrdersPage />,
                },
            ],
        },
        // no auth required
        {
            path: urlConfig.pages.login.href,
            element: <LoginPage />,
        },
        // other
        {
            path: '*',
            element: <NotFoundPage />,
        },
    ])
})
Routes.displayName = Routes.name
