import { Toggle } from 'components/ui/toggle'
import { defaultIconProps } from 'config/icons.config'
import { Image, ImageOff } from 'lucide-react'
import { memo, useState } from 'react'

interface ImageToggleProps extends React.ComponentProps<typeof Toggle> {
    initialState?: boolean
}
export const ImageToggle: React.FC<ImageToggleProps> = memo(({ defaultPressed = false, ...props }) => {
    const [pressed, setPressed] = useState<boolean>(defaultPressed)

    return (
        <Toggle pressed={pressed} onPressedChange={setPressed} size="icon" {...props}>
            {pressed ? <ImageOff {...defaultIconProps} /> : <Image {...defaultIconProps} />}
        </Toggle>
    )
})
ImageToggle.displayName = 'ImageToggle'
