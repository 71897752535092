import { AdspaceEntity, AdspaceTypeEnum } from '_graphql/graphql'
import { FullscreenImagePreview } from 'components/fullscreen-image-preview-modal'
import { getLocaleDateNumbers } from 'config/dayjs.config'
import { defaultIconProps } from 'config/icons.config'
import { Check, X } from 'lucide-react'
import { AdspaceImage } from 'modules/adspace/components/adspace-image'
import { getAdspaceSideLetter } from 'modules/adspace/utils/get-adspace-side-letter'
import { memo } from 'react'
import { cn } from 'utils/cn'

interface AdspaceSidesImagesProps extends React.ComponentProps<'div'> {
    adspace: AdspaceEntity
}
export const AdspaceSidesImages: React.FC<AdspaceSidesImagesProps> = memo(({ adspace, ...props }) => {
    return (
        <div
            {...props}
            className={cn(
                'grid grid-cols-1 gap-5 md:grid-cols-2',
                adspace.type === AdspaceTypeEnum.Oneside && 'mx-auto max-w-full grid-cols-1 md:max-w-[50%] md:grid-cols-1',
            )}
        >
            {adspace.sides.map((side, index) => (
                <div
                    key={index + side.text}
                    className={cn('flex flex-col gap-3', adspace.type === AdspaceTypeEnum.Oneside && 'mx-auto')}
                >
                    {/* images only for first 2 sides */}
                    {index < 2 && (
                        <FullscreenImagePreview
                            src={side.image || '/assets/adspace-placeholder.png'}
                            alt={`Зображення сторони площини ${adspace.id}`}
                        >
                            <AdspaceImage
                                src={side.image || '/assets/adspace-placeholder.png'}
                                alt={`Зображення сторони площини ${adspace.id}`}
                                className="aspect-[6/4] rounded-md border object-cover"
                            />
                        </FullscreenImagePreview>
                    )}
                    <div>
                        <p className="flex justify-between">
                            <span className="flex items-center">
                                Сторона {getAdspaceSideLetter(adspace.type, index)} -{' '}
                                {side.busyTo ? (
                                    <>
                                        Зайнята <X {...defaultIconProps} className="ml-0.5" />
                                    </>
                                ) : (
                                    <>
                                        Вільна <Check {...defaultIconProps} className="ml-1" />
                                    </>
                                )}
                            </span>
                            {side.busyTo && (
                                <span className="text-muted-foreground text-sm">До {getLocaleDateNumbers(side.busyTo)}</span>
                            )}
                        </p>
                        {side.text && <p className="text-muted-foreground text-sm">{side.text}</p>}
                    </div>
                </div>
            ))}
        </div>
    )
})
AdspaceSidesImages.displayName = 'AdspaceSidesImages'
