import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import weekOfYear from 'dayjs/plugin/weekOfYear'

dayjs.extend(localeData)
dayjs.extend(weekOfYear)

type GetLocaleDate = (date?: string | Date) => string
export const getLocaleDate: GetLocaleDate = date => dayjs(date).format('DD MMMM YYYY')

type GetLocaleDateNumbers = (date?: string | Date) => string
export const getLocaleDateNumbers: GetLocaleDateNumbers = date => dayjs(date).format('DD.MM.YYYY')

type GetFullLocaleDateNumbers = (date?: string | Date) => string
export const getFullLocaleDateNumbers: GetFullLocaleDateNumbers = date => dayjs(date).format('DD.MM.YYYY, HH:mm')

type GetLocaleTime = (date?: string | Date) => string
export const getLocaleTime: GetLocaleTime = date => dayjs(date).format('HH:mm')
