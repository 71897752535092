import { Button } from 'components/ui/button'
import { CardDescription, CardTitle } from 'components/ui/card'
import { urlConfig } from 'config/url.config'
import { memo } from 'react'
import { Link } from 'react-router-dom'

interface NotFoundPageProps {}
export const NotFoundPage: React.FC<NotFoundPageProps> = memo(() => {
    return (
        <main className="container flex h-full flex-col items-center justify-center">
            <CardTitle className="text-center text-4xl font-medium leading-tight sm:text-5xl sm:leading-tight md:text-5xl md:leading-tight lg:text-6xl lg:leading-tight xl:leading-tight 2xl:leading-tight">
                404 | Тут реклами немає
            </CardTitle>
            <CardDescription className="mt-3 text-center md:max-w-3xl">
                На жаль, ми не змогли знайти сторінку, яку ви шукаєте. Можливо, вона була переміщена, видалена або ніколи не
                існувала.
            </CardDescription>
            <Button size="xl" asChild className="mt-8">
                <Link to={urlConfig.pages.main.href}>Головна</Link>
            </Button>
            <div className="mb-20" />
        </main>
    )
})
NotFoundPage.displayName = NotFoundPage.name
