import { toast } from 'components/ui/use-toast'

export const globalToasts = {
    unknownError: () =>
        toast({
            title: 'Невідома помилка',
            description: 'Щось пішло не так. Спробуйте ще раз пізніше.',
            variant: 'destructive',
        }),
} as const
