import { useAllOrdersQuery } from '_graphql/graphql'
import { FullPageLoader } from 'components/full-page-loader'
import { OrderCard } from 'modules/order/components/order-card'
import { ErrorPage } from 'pages/error-page'
import { memo } from 'react'

interface OrdersPageProps {}
export const OrdersPage: React.FC<OrdersPageProps> = memo(() => {
    const { data, loading, error } = useAllOrdersQuery()

    if (loading) {
        return <FullPageLoader />
    }
    if (error || !data) {
        return <ErrorPage />
    }

    return (
        <div className="container space-y-5 py-5">
            {data.allOrders?.length === 0 ? (
                <p className="text-sm text-center text-muted-foreground">Замовлень поки немає</p>
            ) : (
                [...data.allOrders].reverse().map(order => <OrderCard key={order.id} order={order} />)
            )}
        </div>
    )
})
OrdersPage.displayName = 'OrdersPage'
