import { Loader } from 'components/loader'
import { memo } from 'react'

interface FullPageLoaderProps {}
export const FullPageLoader: React.FC<FullPageLoaderProps> = memo(() => {
    return (
        <div className="bg-background animate-in fade-in-0 fixed left-0 top-0 flex h-screen w-screen items-center justify-center transition-all duration-300">
            <Loader className="text-muted" />
        </div>
    )
})
FullPageLoader.displayName = FullPageLoader.name
