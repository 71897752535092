import { Button } from 'components/ui/button'
import { Calendar } from 'components/ui/calendar'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { getLocaleDateNumbers } from 'config/dayjs.config'
import { CalendarIcon } from 'lucide-react'
import { memo } from 'react'
import { DayPickerSingleProps } from 'react-day-picker'
import { cn } from 'utils/cn'

interface DatePickerProps extends Omit<DayPickerSingleProps, 'mode'> {}
export const DatePicker: React.FC<DatePickerProps> = memo(({ selected, onSelect, className, ...props }) => {
    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    variant={'outline'}
                    className={cn(
                        'w-[280px] justify-start text-left font-normal',
                        !selected && 'text-muted-foreground',
                        className,
                    )}
                >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {selected ? getLocaleDateNumbers(selected) : <span>Виберіть дату</span>}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
                <Calendar {...props} mode="single" selected={selected} onSelect={onSelect} initialFocus />
            </PopoverContent>
        </Popover>
    )
})
DatePicker.displayName = DatePicker.name
