import { ENV, dotenv } from 'config/dotenv.config'
import { mapConfig } from 'config/map.config'
import 'mapbox-gl/dist/mapbox-gl.css'
import { memo, useCallback, useMemo, useRef } from 'react'
import ReactMapGL, { GeolocateControl, type Fog, type MapRef, type ViewState } from 'react-map-gl'
import { cn } from 'utils/cn'

interface MapProps extends React.ComponentProps<'div'> {
    lat?: number
    lng?: number
    zoom?: number
    innerClassName?: string
}
export const Map: React.FC<React.PropsWithChildren<MapProps>> = memo(
    ({
        lat = mapConfig.defaultLat,
        lng = mapConfig.defaultLng,
        zoom = mapConfig.defaultZoom,
        innerClassName,
        className,
        children,
        ...props
    }) => {
        const mapRef = useRef<MapRef | null>(null)

        const initialViewport = useMemo<ViewState>(
            () => ({
                ...mapConfig.defaultViewport,
                latitude: lat,
                longitude: lng,
                zoom,
            }),
            [lat, lng, zoom],
        )
        const fog = useMemo<Fog>(() => ({}), [])

        const handleRef = useCallback((instance: MapRef) => {
            mapRef.current = instance
        }, [])

        return (
            <div {...props} className={cn('relative h-full w-full', className)}>
                <div className={cn('absolute h-full w-full', innerClassName)}>
                    <ReactMapGL
                        ref={handleRef}
                        mapStyle={dotenv.get(ENV.MAPBOX_STYLE)}
                        mapboxAccessToken={dotenv.get(ENV.MAPBOX_TOKEN)}
                        initialViewState={initialViewport}
                        projection={mapConfig.projection}
                        maxZoom={mapConfig.maxZoom}
                        fog={fog}
                        attributionControl={false}
                        cooperativeGestures
                        style={mapConfig.initialMapStyles}
                    >
                        <GeolocateControl position="top-left" />
                        {children}
                    </ReactMapGL>
                </div>
            </div>
        )
    },
)
Map.displayName = Map.name
