import { AdspaceSideEntity } from '_graphql/graphql'
import { PieChart } from 'components/pie-chart'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { memo, useMemo } from 'react'

interface AdSpacesPieChartProps extends React.ComponentProps<typeof Card> {
    data: Array<{
        sides: Pick<AdspaceSideEntity, 'busyTo'>[]
    }>
}
export const AdSpacesPieChart: React.FC<AdSpacesPieChartProps> = memo(({ data, ...props }) => {
    const chartData = useMemo(() => {
        const busy = data.filter(item => item.sides.every(side => side.busyTo)).length
        const partiallyBusy = data.filter(
            item => item.sides.some(side => side.busyTo) && item.sides.some(side => !side.busyTo),
        ).length
        const free = data.filter(item => item.sides.every(side => !side.busyTo)).length

        return [
            {
                name: 'Зайнято',
                value: busy,
            },
            {
                name: 'Частково зайнято',
                value: partiallyBusy,
            },
            {
                name: 'Вільно',
                value: free,
            },
        ]
    }, [data])

    return (
        <Card {...props}>
            <CardHeader>
                <CardTitle>Зайнятість рекламних площин</CardTitle>
                <CardDescription>Співвідношення зайнятих, частково зайнятих та вільних площин</CardDescription>
            </CardHeader>

            <CardContent>
                <PieChart data={chartData} height={350} />
            </CardContent>
        </Card>
    )
})

AdSpacesPieChart.displayName = AdSpacesPieChart.name
