import { InlineCode } from 'components/ui/code'
import { toast } from 'components/ui/use-toast'

export const userToastMessages = {
    login: {
        success: (name: string) =>
            toast({
                title: 'Вхід успішний',
                description: (
                    <>
                        Привіт, <InlineCode>{name}</InlineCode>!
                    </>
                ),
            }),
        error: () =>
            toast({
                title: 'Помилка входу',
                description: 'Будь ласка, спробуйте ще раз пізніше.',
                variant: 'destructive',
            }),
        successWithEmail: (email: string) =>
            toast({
                title: 'Перший крок виконано!',
                description: (
                    <>
                        Перевірте свою електронну скриньку <InlineCode>{email}</InlineCode>, щоб підтвердити вхід.
                    </>
                ),
            }),
    },
} as const
