// add filter buttons by type

import { useAllAdspacesQuery } from '_graphql/graphql'
import { FullPageLoader } from 'components/full-page-loader'
import { Map } from 'components/map'
import { AdspaceCreateConfirmable } from 'modules/adspace/components/adspace-create-confirmable'
import { AdspaceModal } from 'modules/adspace/components/adspace-modal'
import { isAdspaceBusy } from 'modules/adspace/utils/is-adspace-busy'
import { MapInputFilter } from 'modules/map/components/map-input-filter'
import { MapMarker } from 'modules/map/components/map-markers'
import { ErrorPage } from 'pages/error-page'
import { memo, useMemo, useState } from 'react'

interface MapPageProps {}
export const MapPage: React.FC<MapPageProps> = memo(() => {
    const { data, loading, error } = useAllAdspacesQuery()

    const [idFilter, setIdFilter] = useState<string>('')

    const filteredAdspaces = useMemo(() => {
        if (idFilter !== '') {
            return data?.allAdspaces.filter(adspace => String(adspace.id) == idFilter || String(adspace.number) == idFilter) ?? []
        }
        return data?.allAdspaces ?? []
    }, [data, idFilter])

    if (loading) {
        return <FullPageLoader />
    }
    if (error) {
        return <ErrorPage />
    }

    return (
        <Map className="relative">
            <div className="absolute right-5 top-5 flex gap-2">
                <MapInputFilter value={idFilter} setValue={setIdFilter} className="bg-background w-fit" />
                <AdspaceCreateConfirmable className="bg-background" />
            </div>
            {filteredAdspaces.map(adspace => (
                <AdspaceModal key={adspace.id} adspace={adspace}>
                    <MapMarker
                        key={adspace.id}
                        lat={adspace.lat}
                        lng={adspace.lng}
                        type={adspace.type}
                        isBusy={isAdspaceBusy(adspace)}
                    />
                </AdspaceModal>
            ))}
        </Map>
    )
})
MapPage.displayName = MapPage.name
