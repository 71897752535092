import { useLoginMutation } from '_graphql/graphql'
import { firebaseAuth } from 'config/firebase.config'
import { urlConfig } from 'config/url.config'
import { isSignInWithEmailLink, sendSignInLinkToEmail } from 'firebase/auth'
import { AUTH_LOCAL_STORAGE_KEY } from 'modules/user/components/hoc/auth-provider'
import { OnSignInWithGoogle } from 'modules/user/components/sign-in-with-google-button'
import { userToastMessages } from 'modules/user/constants/user-toast-messages'
import { useCallback, useEffect, useState } from 'react'
import { useSignIn } from 'react-auth-kit'
import { useNavigate } from 'react-router-dom'

type AuthState = Parameters<OnSignInWithGoogle>[0]

export const useLogin = () => {
    const signIn = useSignIn()
    const navigate = useNavigate()
    const [loginMutation] = useLoginMutation()

    const [isButtonDisabled, setButtonDisabled] = useState<boolean>(false)

    const login = useCallback<(params: { authState: AuthState; callbackUrl: string }) => Promise<void>>(
        async ({ authState, callbackUrl }) => {
            try {
                if (!authState.email || !authState.name) {
                    throw new Error('Invalid auth state')
                }
                setButtonDisabled(true)
                const res = await loginMutation({
                    variables: {
                        email: authState.email,
                    },
                })
                if ((!!res.errors?.length && res.errors.length > 0) || !res.data?.login) {
                    throw new Error(`Response error: ${JSON.stringify(res.errors)}`)
                }
                signIn({
                    token: res.data.login.token,
                    expiresIn: res.data.login.expiresIn,
                    tokenType: 'Bearer',
                    authState: {
                        ...authState,
                    },
                })
                userToastMessages.login.success(authState.name)
                navigate(callbackUrl)
            } catch (error) {
                console.error(error)
                userToastMessages.login.error()
            } finally {
                setButtonDisabled(false)
            }
        },
        [loginMutation, navigate, signIn],
    )

    const signInWithGoogle = useCallback<OnSignInWithGoogle>(
        async user => {
            await login({
                authState: {
                    name: 'Unknown user',
                    ...user,
                },
                callbackUrl: urlConfig.pages.main.href,
            })
        },
        [login],
    )

    const signInWithEmailLink = useCallback<(email: string, cb?: () => void | Promise<void>) => Promise<void>>(
        async (email, cb) => {
            try {
                await sendSignInLinkToEmail(firebaseAuth, email, {
                    url: window.location.href,
                    handleCodeInApp: true,
                })
                localStorage.setItem(AUTH_LOCAL_STORAGE_KEY.GOOGLE_PASSWORDLESS, email)
                cb?.()
                userToastMessages.login.successWithEmail(email)
            } catch (error) {
                userToastMessages.login.error()
            }
        },
        [],
    )

    // sign in with google email passwordless link
    useEffect(() => {
        try {
            if (!isSignInWithEmailLink(firebaseAuth, window.location.href)) {
                return
            }
            const email = window.localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.GOOGLE_PASSWORDLESS)
            window.localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY.GOOGLE_PASSWORDLESS)
            if (!email) {
                return
            }
            login({
                authState: {
                    email,
                    name: email.split('@')[0],
                },
                callbackUrl: urlConfig.pages.main.href,
            })
        } catch (error) {
            userToastMessages.login.error()
        }
    }, [login])

    return {
        isButtonDisabled,
        login,
        signInWithGoogle,
        signInWithEmailLink,
    } as const
}
