import { ENV, dotenv } from 'config/dotenv.config'
import { websiteConfig } from 'config/website.config'

export const urlConfig = {
    WEBSITE_URL: dotenv.get(ENV.WEBSITE_URL),
    ADMIN_URL: dotenv.get(ENV.ADMIN_URL),
    API_URL: dotenv.get(ENV.API_URL),
    pages: {
        main: {
            href: '/',
            label: 'Головна',
        },
        map: {
            href: '/map',
            label: 'Карта',
        },
        orders: {
            href: '/orders',
            label: 'Замовлення',
        },
        login: {
            href: '/login',
            label: 'Вхід',
        },
    },
    website: {
        adspaceId: '/adspace/:id',
    },
    other: {
        website: {
            href: dotenv.get(ENV.WEBSITE_URL),
            label: websiteConfig.name,
            props: {
                target: '_blank',
                rel: 'noopener noreferrer',
            },
        },
        firebase: {
            href: 'https://console.firebase.google.com/project/advertize-lviv/overview',
            label: 'Firebase',
            props: {
                target: '_blank',
                rel: 'noopener noreferrer',
            },
        },
    },
} as const
