import { z } from 'zod'

export const LoginSchema = z.object({
    email: z
        .string()
        .email('Введіть правильний e-mail')
        .min(5, 'E-mail не може бути коротшою за 5 символів')
        .max(255, 'E-mail не може бути довшою за 255 символів'),
})
export type LoginSchemaType = z.infer<typeof LoginSchema>

export const LoginResponseSchema = z.object({
    token: z.string().nonempty(),
    expiresIn: z.number().int().positive(),
})
export type LoginResponseSchemaType = z.infer<typeof LoginResponseSchema>
