import { useAllAdspacesQuery } from '_graphql/graphql'
import { FullPageLoader } from 'components/full-page-loader'
import { AdspaceChartsStatistics } from 'modules/adspace/components/adspace-charts-statistics'
import { AdspaceNumberStatistics } from 'modules/adspace/components/adspace-number-statistics'
import { ErrorPage } from 'pages/error-page'
import { memo } from 'react'

interface MainPageProps {}
export const MainPage: React.FC<MainPageProps> = memo(() => {
    const { data, loading, error } = useAllAdspacesQuery()

    if (loading) {
        return <FullPageLoader />
    }
    if (error || !data) {
        return <ErrorPage />
    }

    return (
        <div className="container space-y-5 py-5">
            <AdspaceNumberStatistics adspaces={data.allAdspaces} />
            <AdspaceChartsStatistics adspaces={data.allAdspaces} />
        </div>
    )
})
MainPage.displayName = MainPage.name
