import { ENV, dotenv } from 'config/dotenv.config'
import { initializeApp } from 'firebase/app'
import 'firebase/app-check'
import { GoogleAuthProvider, getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { NODE_ENV_DEV } from 'utils/NODE_ENV'

if (NODE_ENV_DEV) {
    // @ts-expect-error firebase app check debug token
    window.FIREBASE_APPCHECK_DEBUG_TOKEN = true
}

const firebaseConfig = {
    apiKey: dotenv.get(ENV.FIRE_API_KEY),
    authDomain: dotenv.get(ENV.FIRE_AUTH_DOMAIN),
    projectId: dotenv.get(ENV.FIRE_PROJECT_ID),
    storageBucket: dotenv.get(ENV.FIRE_STORAGE_BUCKET),
    messagingSenderId: dotenv.get(ENV.FIRE_MESSAGING_SENDER_ID),
    appId: dotenv.get(ENV.FIRE_APP_ID),
    measurementId: dotenv.get(ENV.FIRE_MEASUREMENT_ID),
}

export const firebaseApp = initializeApp(firebaseConfig)
export const firebaseAuth = getAuth(firebaseApp)
export const firebaseStorage = getStorage(firebaseApp)
export const firebaseProvider = new GoogleAuthProvider()
// export const firebaseAppCheck = initializeAppCheck(firebaseApp, {
//     provider: new ReCaptchaV3Provider(dotenv.get(ENV.FIRE_APPCHECK_KEY)),
//     isTokenAutoRefreshEnabled: true,
// })

export const firebaseStorageDirectories = {
    adspaces: 'adspaces',
}
