import { urlConfig } from 'config/url.config'
import { RequireAuth } from 'modules/user/components/hoc/require-auth'
import { memo } from 'react'
import { Outlet } from 'react-router-dom'

interface AuthLayoutProps {}
export const AuthLayout: React.FC<AuthLayoutProps> = memo(() => {
    return (
        <RequireAuth loginPath={urlConfig.pages.login.href}>
            <Outlet />
        </RequireAuth>
    )
})
AuthLayout.displayName = AuthLayout.name
