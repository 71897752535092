import { AdspaceTypeEnum } from '_graphql/graphql'

export const getAdspaceStringType = (type: AdspaceTypeEnum) => {
    switch (type) {
        case AdspaceTypeEnum.Arka:
            return 'Арка'
        case AdspaceTypeEnum.Billboard:
            return 'Білборд'
        case AdspaceTypeEnum.Duplex:
            return 'Білборд'
        case AdspaceTypeEnum.Oneside:
            return 'Білборд'
        default:
            return ''
    }
}
