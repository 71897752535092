import { OrderEntity } from '_graphql/graphql'
import { Button } from 'components/ui/button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { getFullLocaleDateNumbers } from 'config/dayjs.config'
import { defaultIconProps } from 'config/icons.config'
import { Phone } from 'lucide-react'
import { memo } from 'react'

interface OrderCardProps extends React.ComponentProps<typeof Card> {
    order: OrderEntity
}
export const OrderCard: React.FC<OrderCardProps> = memo(({ order, ...props }) => {
    return (
        <Card {...props}>
            <CardHeader className="flex flex-row justify-between">
                <div className="space-y-1.5">
                    <CardTitle>{order.name}</CardTitle>
                    <CardDescription>{order.phone}</CardDescription>
                </div>
                <Button size="icon" asChild>
                    <a href={`tel:${order.phone}`}>
                        <Phone {...defaultIconProps} />
                    </a>
                </Button>
            </CardHeader>
            <CardContent>
                <p>{order.text}</p>
                <p className="text-sm text-muted-foreground mt-2">{getFullLocaleDateNumbers(order.createdAt)}</p>
            </CardContent>
        </Card>
    )
})
OrderCard.displayName = 'OrderCard'
