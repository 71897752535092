import { DropdownMenuItem } from 'components/ui/dropdown-menu'
import { defaultIconProps } from 'config/icons.config'
import { X } from 'lucide-react'
import { memo } from 'react'
import { cn } from 'utils/cn'

interface CommandItemWithIconProps extends React.ComponentProps<typeof DropdownMenuItem> {
    Comp: React.ComponentType<React.ComponentProps<typeof DropdownMenuItem>>
    Icon: React.ComponentType<React.ComponentProps<typeof X>>
    iconClassName?: string
}
export const CommandItemWithIcon: React.FC<CommandItemWithIconProps> = memo(
    ({ Comp, Icon, iconClassName, children, ...props }) => {
        return (
            <Comp {...props}>
                <Icon {...defaultIconProps} className={cn('mr-2 h-4 w-4', iconClassName)} />
                {children}
            </Comp>
        )
    },
)
CommandItemWithIcon.displayName = CommandItemWithIcon.name
