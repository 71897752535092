import { Button } from 'components/ui/button'
import { CardDescription, CardTitle } from 'components/ui/card'
import { urlConfig } from 'config/url.config'
import { memo } from 'react'
import { Link } from 'react-router-dom'

interface ErrorPageProps {}
export const ErrorPage: React.FC<ErrorPageProps> = memo(() => {
    return (
        <main className="container flex h-full flex-col items-center justify-center">
            <CardTitle className="text-center text-4xl font-medium leading-tight sm:text-5xl sm:leading-tight md:text-5xl md:leading-tight lg:text-6xl lg:leading-tight xl:leading-tight 2xl:leading-tight">
                Щось пішло не так
            </CardTitle>
            <CardDescription className="mt-3 text-center md:max-w-3xl">
                На жаль, щось перешкоджає нормальному відображенню сторінки. Наші технічні спеціалісти вже проінформовані про це
                та працюють над вирішенням проблеми.
            </CardDescription>
            <Button size="xl" asChild className="mt-8">
                <Link to={urlConfig.pages.main.href}>Головна</Link>
            </Button>
            <div className="mb-20" />
        </main>
    )
})
ErrorPage.displayName = ErrorPage.name
