import { zodResolver } from '@hookform/resolvers/zod'
import { Loader } from 'components/loader'
import { Button } from 'components/ui/button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { SignInWithGoogleButton } from 'modules/user/components/sign-in-with-google-button'
import { useLogin } from 'modules/user/hooks/use-login'
import { LoginSchema, LoginSchemaType } from 'modules/user/types/login.schema'
import { memo, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { cn } from 'utils/cn'

interface LoginPageProps {}
export const LoginPage: React.FC<LoginPageProps> = memo(() => {
    const { isButtonDisabled, signInWithGoogle, signInWithEmailLink } = useLogin()

    const form = useForm<LoginSchemaType>({
        resolver: zodResolver(LoginSchema),
        defaultValues: {
            email: '',
        },
    })

    const onSubmit = useCallback(
        async (data: LoginSchemaType) => {
            await signInWithEmailLink(data.email, () => {
                form.reset()
            })
        },
        [form, signInWithEmailLink],
    )

    return (
        <div className="flex h-full items-center justify-center">
            <Card className="max-w-[500px]">
                <CardHeader>
                    <CardTitle>Увійдіть у свій акаунт, щоб продовжити</CardTitle>
                    <CardDescription>
                        Скористайтеся своїм обліковим записом Google або електронною адресою, щоб увійти.
                    </CardDescription>
                </CardHeader>
                <CardContent className="space-y-6">
                    {/* form */}
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                            <FormField
                                control={form.control}
                                name="email"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>E-mail</FormLabel>
                                        <FormControl>
                                            <Input type="email" placeholder="someemail@email.com" {...field} required />
                                        </FormControl>
                                        <FormDescription className="text-xs">
                                            Після введення адреси електронної пошти Вам потрібно буде підтвердити її, перейшовши
                                            за посиланням у листі.
                                        </FormDescription>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <Button type="submit" variant="default" disabled={isButtonDisabled}>
                                <Loader className={cn('mr-2', !isButtonDisabled && 'hidden')} />
                                Увійти
                            </Button>
                        </form>
                    </Form>

                    {/* divider */}
                    <div className="relative">
                        <div className="absolute inset-0 flex items-center">
                            <span className="w-full border-t" />
                        </div>
                        <div className="relative flex justify-center text-xs uppercase">
                            <span className="bg-card text-muted-foreground px-2">Або продовжити з</span>
                        </div>
                    </div>

                    {/* other */}
                    <SignInWithGoogleButton onClick={signInWithGoogle} disabled={isButtonDisabled} className="w-full" />
                </CardContent>
            </Card>
        </div>
    )
})
LoginPage.displayName = LoginPage.name
