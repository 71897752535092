import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string }
    String: { input: string; output: string }
    Boolean: { input: boolean; output: boolean }
    Int: { input: number; output: number }
    Float: { input: number; output: number }
}

export type AdspaceCreateInput = {
    lat: Scalars['Float']['input']
    lng: Scalars['Float']['input']
    number: Scalars['Int']['input']
    sides: Array<AdspaceSideCreateInput>
    type: AdspaceTypeEnum
}

export type AdspaceEntity = {
    __typename?: 'AdspaceEntity'
    id: Scalars['String']['output']
    lat: Scalars['Float']['output']
    lng: Scalars['Float']['output']
    number: Scalars['Int']['output']
    sides: Array<AdspaceSideEntity>
    type: AdspaceTypeEnum
}

export type AdspaceGridEntity = {
    __typename?: 'AdspaceGridEntity'
    busyTo: Scalars['String']['output']
    id: Scalars['String']['output']
    image: Scalars['String']['output']
    isBusy: Scalars['Boolean']['output']
    isBusyText: Scalars['String']['output']
    lat: Scalars['Float']['output']
    letter: Scalars['String']['output']
    lng: Scalars['Float']['output']
    number: Scalars['Int']['output']
    text: Scalars['String']['output']
    type: AdspaceTypeEnum
}

export type AdspaceSideCreateInput = {
    busyTo: Scalars['String']['input']
    image: Scalars['String']['input']
    text: Scalars['String']['input']
}

export type AdspaceSideEntity = {
    __typename?: 'AdspaceSideEntity'
    busyTo: Scalars['String']['output']
    image: Scalars['String']['output']
    text: Scalars['String']['output']
}

export type AdspaceSideUpdateInput = {
    busyTo: Scalars['String']['input']
    image: Scalars['String']['input']
    text: Scalars['String']['input']
}

export enum AdspaceTypeEnum {
    Arka = 'Arka',
    Billboard = 'Billboard',
    Duplex = 'Duplex',
    Oneside = 'Oneside',
}

export type AdspaceUpdateInput = {
    id: Scalars['String']['input']
    lat: Scalars['Float']['input']
    lng: Scalars['Float']['input']
    number: Scalars['Int']['input']
    sides: Array<AdspaceSideUpdateInput>
    type: AdspaceTypeEnum
}

export type AuthLoginEntity = {
    __typename?: 'AuthLoginEntity'
    expiresIn: Scalars['Int']['output']
    token: Scalars['String']['output']
}

export type AuthLoginInput = {
    email: Scalars['String']['input']
}

export type Mutation = {
    __typename?: 'Mutation'
    createAdspace: AdspaceEntity
    createOrder: OrderEntity
    deleteAdspace: AdspaceEntity
    login: AuthLoginEntity
    updateAdspace: AdspaceEntity
}

export type MutationCreateAdspaceArgs = {
    data: AdspaceCreateInput
}

export type MutationCreateOrderArgs = {
    data: OrderCreateInput
}

export type MutationDeleteAdspaceArgs = {
    id: Scalars['String']['input']
}

export type MutationLoginArgs = {
    data: AuthLoginInput
}

export type MutationUpdateAdspaceArgs = {
    data: AdspaceUpdateInput
}

export type OrderCreateInput = {
    name: Scalars['String']['input']
    phone: Scalars['String']['input']
    text: Scalars['String']['input']
}

export type OrderEntity = {
    __typename?: 'OrderEntity'
    createdAt: Scalars['String']['output']
    id: Scalars['String']['output']
    name: Scalars['String']['output']
    phone: Scalars['String']['output']
    text: Scalars['String']['output']
}

export type Query = {
    __typename?: 'Query'
    adspaceById: AdspaceEntity
    allAdspaces: Array<AdspaceEntity>
    allAdspacesForGrid: Array<AdspaceGridEntity>
    allOrders: Array<OrderEntity>
}

export type QueryAdspaceByIdArgs = {
    id: Scalars['String']['input']
}

export type CreateAdspaceMutationVariables = Exact<{
    data: AdspaceCreateInput
}>

export type CreateAdspaceMutation = {
    __typename?: 'Mutation'
    createAdspace: {
        __typename?: 'AdspaceEntity'
        id: string
        number: number
        lat: number
        lng: number
        type: AdspaceTypeEnum
        sides: Array<{ __typename?: 'AdspaceSideEntity'; busyTo: string; image: string; text: string }>
    }
}

export type DeleteAdspaceMutationVariables = Exact<{
    id: Scalars['String']['input']
}>

export type DeleteAdspaceMutation = {
    __typename?: 'Mutation'
    deleteAdspace: { __typename?: 'AdspaceEntity'; id: string; number: number }
}

export type UpdateAdspaceMutationVariables = Exact<{
    data: AdspaceUpdateInput
}>

export type UpdateAdspaceMutation = {
    __typename?: 'Mutation'
    updateAdspace: {
        __typename?: 'AdspaceEntity'
        id: string
        number: number
        lat: number
        lng: number
        type: AdspaceTypeEnum
        sides: Array<{ __typename?: 'AdspaceSideEntity'; busyTo: string; image: string; text: string }>
    }
}

export type AdspaceByIdQueryVariables = Exact<{
    id: Scalars['String']['input']
}>

export type AdspaceByIdQuery = {
    __typename?: 'Query'
    adspaceById: {
        __typename?: 'AdspaceEntity'
        id: string
        number: number
        lat: number
        lng: number
        type: AdspaceTypeEnum
        sides: Array<{ __typename?: 'AdspaceSideEntity'; busyTo: string; image: string; text: string }>
    }
}

export type AllAdspacesQueryVariables = Exact<{ [key: string]: never }>

export type AllAdspacesQuery = {
    __typename?: 'Query'
    allAdspaces: Array<{
        __typename?: 'AdspaceEntity'
        id: string
        number: number
        lat: number
        lng: number
        type: AdspaceTypeEnum
        sides: Array<{ __typename?: 'AdspaceSideEntity'; busyTo: string; image: string; text: string }>
    }>
}

export type AllOrdersQueryVariables = Exact<{ [key: string]: never }>

export type AllOrdersQuery = {
    __typename?: 'Query'
    allOrders: Array<{ __typename?: 'OrderEntity'; id: string; createdAt: string; name: string; phone: string; text: string }>
}

export type LoginMutationVariables = Exact<{
    email: Scalars['String']['input']
}>

export type LoginMutation = {
    __typename?: 'Mutation'
    login: { __typename?: 'AuthLoginEntity'; token: string; expiresIn: number }
}

export const CreateAdspaceDocument = gql`
    mutation CreateAdspace($data: AdspaceCreateInput!) {
        createAdspace(data: $data) {
            id
            number
            lat
            lng
            type
            sides {
                busyTo
                image
                text
            }
        }
    }
`
export type CreateAdspaceMutationFn = Apollo.MutationFunction<CreateAdspaceMutation, CreateAdspaceMutationVariables>

/**
 * __useCreateAdspaceMutation__
 *
 * To run a mutation, you first call `useCreateAdspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdspaceMutation, { data, loading, error }] = useCreateAdspaceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAdspaceMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateAdspaceMutation, CreateAdspaceMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<CreateAdspaceMutation, CreateAdspaceMutationVariables>(CreateAdspaceDocument, options)
}
export type CreateAdspaceMutationHookResult = ReturnType<typeof useCreateAdspaceMutation>
export type CreateAdspaceMutationResult = Apollo.MutationResult<CreateAdspaceMutation>
export type CreateAdspaceMutationOptions = Apollo.BaseMutationOptions<CreateAdspaceMutation, CreateAdspaceMutationVariables>
export const DeleteAdspaceDocument = gql`
    mutation DeleteAdspace($id: String!) {
        deleteAdspace(id: $id) {
            id
            number
        }
    }
`
export type DeleteAdspaceMutationFn = Apollo.MutationFunction<DeleteAdspaceMutation, DeleteAdspaceMutationVariables>

/**
 * __useDeleteAdspaceMutation__
 *
 * To run a mutation, you first call `useDeleteAdspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdspaceMutation, { data, loading, error }] = useDeleteAdspaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAdspaceMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteAdspaceMutation, DeleteAdspaceMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<DeleteAdspaceMutation, DeleteAdspaceMutationVariables>(DeleteAdspaceDocument, options)
}
export type DeleteAdspaceMutationHookResult = ReturnType<typeof useDeleteAdspaceMutation>
export type DeleteAdspaceMutationResult = Apollo.MutationResult<DeleteAdspaceMutation>
export type DeleteAdspaceMutationOptions = Apollo.BaseMutationOptions<DeleteAdspaceMutation, DeleteAdspaceMutationVariables>
export const UpdateAdspaceDocument = gql`
    mutation UpdateAdspace($data: AdspaceUpdateInput!) {
        updateAdspace(data: $data) {
            id
            number
            lat
            lng
            type
            sides {
                busyTo
                image
                text
            }
        }
    }
`
export type UpdateAdspaceMutationFn = Apollo.MutationFunction<UpdateAdspaceMutation, UpdateAdspaceMutationVariables>

/**
 * __useUpdateAdspaceMutation__
 *
 * To run a mutation, you first call `useUpdateAdspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdspaceMutation, { data, loading, error }] = useUpdateAdspaceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAdspaceMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateAdspaceMutation, UpdateAdspaceMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<UpdateAdspaceMutation, UpdateAdspaceMutationVariables>(UpdateAdspaceDocument, options)
}
export type UpdateAdspaceMutationHookResult = ReturnType<typeof useUpdateAdspaceMutation>
export type UpdateAdspaceMutationResult = Apollo.MutationResult<UpdateAdspaceMutation>
export type UpdateAdspaceMutationOptions = Apollo.BaseMutationOptions<UpdateAdspaceMutation, UpdateAdspaceMutationVariables>
export const AdspaceByIdDocument = gql`
    query AdspaceById($id: String!) {
        adspaceById(id: $id) {
            id
            number
            lat
            lng
            type
            sides {
                busyTo
                image
                text
            }
        }
    }
`

/**
 * __useAdspaceByIdQuery__
 *
 * To run a query within a React component, call `useAdspaceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdspaceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdspaceByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdspaceByIdQuery(baseOptions: Apollo.QueryHookOptions<AdspaceByIdQuery, AdspaceByIdQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<AdspaceByIdQuery, AdspaceByIdQueryVariables>(AdspaceByIdDocument, options)
}
export function useAdspaceByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdspaceByIdQuery, AdspaceByIdQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<AdspaceByIdQuery, AdspaceByIdQueryVariables>(AdspaceByIdDocument, options)
}
export type AdspaceByIdQueryHookResult = ReturnType<typeof useAdspaceByIdQuery>
export type AdspaceByIdLazyQueryHookResult = ReturnType<typeof useAdspaceByIdLazyQuery>
export type AdspaceByIdQueryResult = Apollo.QueryResult<AdspaceByIdQuery, AdspaceByIdQueryVariables>
export const AllAdspacesDocument = gql`
    query AllAdspaces {
        allAdspaces {
            id
            number
            lat
            lng
            type
            sides {
                busyTo
                image
                text
            }
        }
    }
`

/**
 * __useAllAdspacesQuery__
 *
 * To run a query within a React component, call `useAllAdspacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAdspacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAdspacesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllAdspacesQuery(baseOptions?: Apollo.QueryHookOptions<AllAdspacesQuery, AllAdspacesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<AllAdspacesQuery, AllAdspacesQueryVariables>(AllAdspacesDocument, options)
}
export function useAllAdspacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllAdspacesQuery, AllAdspacesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<AllAdspacesQuery, AllAdspacesQueryVariables>(AllAdspacesDocument, options)
}
export type AllAdspacesQueryHookResult = ReturnType<typeof useAllAdspacesQuery>
export type AllAdspacesLazyQueryHookResult = ReturnType<typeof useAllAdspacesLazyQuery>
export type AllAdspacesQueryResult = Apollo.QueryResult<AllAdspacesQuery, AllAdspacesQueryVariables>
export const AllOrdersDocument = gql`
    query AllOrders {
        allOrders {
            id
            createdAt
            name
            phone
            text
        }
    }
`

/**
 * __useAllOrdersQuery__
 *
 * To run a query within a React component, call `useAllOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllOrdersQuery(baseOptions?: Apollo.QueryHookOptions<AllOrdersQuery, AllOrdersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<AllOrdersQuery, AllOrdersQueryVariables>(AllOrdersDocument, options)
}
export function useAllOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllOrdersQuery, AllOrdersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<AllOrdersQuery, AllOrdersQueryVariables>(AllOrdersDocument, options)
}
export type AllOrdersQueryHookResult = ReturnType<typeof useAllOrdersQuery>
export type AllOrdersLazyQueryHookResult = ReturnType<typeof useAllOrdersLazyQuery>
export type AllOrdersQueryResult = Apollo.QueryResult<AllOrdersQuery, AllOrdersQueryVariables>
export const LoginDocument = gql`
    mutation Login($email: String!) {
        login(data: { email: $email }) {
            token
            expiresIn
        }
    }
`
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options)
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>
