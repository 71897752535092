import { Dialog, DialogContent, DialogTrigger } from 'components/ui/dialog'
import { forwardRef, memo } from 'react'

interface FullscreenImagePreviewProps extends React.ComponentProps<typeof DialogTrigger> {
    src: React.ComponentProps<'img'>['src']
    alt?: React.ComponentProps<'img'>['alt']
}
export const FullscreenImagePreview = memo(
    forwardRef<React.ComponentRef<typeof DialogTrigger>, React.PropsWithRef<FullscreenImagePreviewProps>>(
        ({ src, alt, ...props }, ref) => {
            return (
                <Dialog>
                    <DialogTrigger ref={ref} {...props} />
                    <DialogContent
                        showClose={false}
                        className="flex w-fit items-center justify-center p-0 outline-none focus:outline-none focus-visible:outline-none md:w-fit"
                    >
                        <img
                            src={src}
                            alt={alt}
                            className="max-h-[90vh] w-fit max-w-[90vw] rounded-[inherit] border object-contain"
                        />
                    </DialogContent>
                </Dialog>
            )
        },
    ),
)
FullscreenImagePreview.displayName = 'ChatFullscreenImagePreview'
