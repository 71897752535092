import { Input } from 'components/ui/input'
import { memo, useCallback } from 'react'

interface MapInputFilterProps extends React.ComponentProps<typeof Input> {
    value: string
    setValue: React.Dispatch<React.SetStateAction<string>>
}
export const MapInputFilter: React.FC<MapInputFilterProps> = memo(({ value, setValue, ...props }) => {
    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault()
            setValue(e.target.value)
        },
        [setValue],
    )

    return <Input {...props} value={value} onChange={onChange} placeholder="ID" />
})
MapInputFilter.displayName = MapInputFilter.name
