import { Button } from 'components/ui/button'
import { Sheet, SheetContent, SheetTrigger } from 'components/ui/sheet'
import { urlConfig } from 'config/url.config'
import { Menu } from 'lucide-react'
import { ThemeSelector } from 'modules/theme/components/theme-selector'
import { UserAvatar } from 'modules/user/components/user-avatar'
import { memo, useState } from 'react'
import { Link } from 'react-router-dom'

const links: Array<{
    href: string
    label: string
    props?: Record<string, unknown>
}> = [urlConfig.pages.main, urlConfig.pages.map, urlConfig.pages.orders, urlConfig.other.website, urlConfig.other.firebase]

interface NavbarProps {}
export const Navbar: React.FC<NavbarProps> = memo(() => {
    const [open, setOopen] = useState<boolean>(false)

    const runCommand = (command?: () => unknown) => {
        setOopen(false)
        command?.()
    }

    return (
        <nav className="bg-background/90 w-full border-b py-4 backdrop-blur-sm">
            <div className="container flex h-full items-center justify-between">
                <div className="flex items-center gap-3">
                    <UserAvatar className="h-9 w-9" />
                    <p className="font-semibold">Advertize/admin</p>
                </div>

                <div className="flex items-center gap-3">
                    <div className="hidden md:block">
                        {links.map(link => (
                            <Button key={link.label} variant="ghost" asChild className="text-muted-foreground font-normal">
                                <Link to={link.href} {...link.props}>
                                    {link.label}
                                </Link>
                            </Button>
                        ))}
                    </div>

                    <ThemeSelector variant="outline" />

                    <Sheet open={open} onOpenChange={setOopen}>
                        <SheetTrigger asChild className="md:hidden">
                            <Button variant="outline" size="icon">
                                <Menu />
                            </Button>
                        </SheetTrigger>
                        <SheetContent className="flex flex-col items-center justify-center">
                            {links.map(link => (
                                <Button
                                    key={link.label}
                                    variant="ghost"
                                    onClick={() => runCommand()}
                                    asChild
                                    className="text-muted-foreground w-full font-normal"
                                >
                                    <Link to={link.href}>{link.label}</Link>
                                </Button>
                            ))}
                        </SheetContent>
                    </Sheet>
                </div>
            </div>
        </nav>
    )
})
Navbar.displayName = Navbar.name
