import { Projection } from 'mapbox-gl'
import { ViewState } from 'react-map-gl'

interface MapConfig {
    defaultLat: number
    defaultLng: number
    defaultZoom: number
    maxZoom: number
    projection: Projection
    initialMapStyles: React.CSSProperties
    defaultViewport: ViewState
}
export const mapConfig: MapConfig = {
    defaultLat: 49.83826,
    defaultLng: 24.02324,
    defaultZoom: 11,
    maxZoom: 16,
    projection: {
        name: 'globe',
    },
    initialMapStyles: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    defaultViewport: {
        latitude: 49.83826,
        longitude: 24.02324,
        zoom: 11,
        bearing: 0,
        pitch: 0,
        padding: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
    },
}
