import { CommandItemWithIcon } from 'components/slot-item-with-icon'
import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from 'components/ui/dropdown-menu'
import { urlConfig } from 'config/url.config'
import { LogIn, LogOut } from 'lucide-react'
import { getFallbackLetters } from 'modules/user/utils/get-fallback-letters'
import { memo, useMemo } from 'react'
import { useAuthUser, useIsAuthenticated, useSignOut } from 'react-auth-kit'
import { useNavigate } from 'react-router-dom'
import { cn } from 'utils/cn'

interface UserAvatarProps extends React.ComponentProps<typeof Avatar> {}
export const UserAvatar: React.FC<UserAvatarProps> = memo(({ className, ...props }) => {
    const isAuth = useIsAuthenticated()()
    const authData = useAuthUser()()
    const signOut = useSignOut()
    const navigate = useNavigate()

    const fallbackLetters = useMemo(() => getFallbackLetters(authData?.email), [authData?.email])

    if (!isAuth) {
        return null
    }

    return (
        <DropdownMenu>
            <DropdownMenuTrigger>
                <Avatar {...props} className={cn('h-8 w-8', className)}>
                    <AvatarImage src={authData?.image} />
                    <AvatarFallback className="capitalize">{fallbackLetters}</AvatarFallback>
                </Avatar>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
                {isAuth ? (
                    <CommandItemWithIcon Comp={DropdownMenuItem} Icon={LogOut} onSelect={() => signOut()}>
                        Вийти
                    </CommandItemWithIcon>
                ) : (
                    <CommandItemWithIcon
                        Comp={DropdownMenuItem}
                        Icon={LogIn}
                        onSelect={() => navigate(urlConfig.pages.login.href)}
                    >
                        Увійти
                    </CommandItemWithIcon>
                )}
            </DropdownMenuContent>
        </DropdownMenu>
    )
})
UserAvatar.displayName = UserAvatar.name
