import { zodResolver } from '@hookform/resolvers/zod'
import { AdspaceEntity, AdspaceTypeEnum, useUpdateAdspaceMutation } from '_graphql/graphql'
import { ImageToggle } from 'components/image-toggle'
import { LoadingButton } from 'components/loading-button'
import { Button } from 'components/ui/button'
import { CardDescription, CardTitle } from 'components/ui/card'
import { DatePicker } from 'components/ui/date-picker'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTrigger } from 'components/ui/dialog'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import { globalToasts } from 'config/global-toasts.constants'
import { defaultIconProps } from 'config/icons.config'
import { urlConfig } from 'config/url.config'
import { Link, PackageMinus, Trash2 } from 'lucide-react'
import { AdspaceDeleteConfirmable } from 'modules/adspace/components/adspace-delete-confirmable'
import { AdspaceSidesImages } from 'modules/adspace/components/adspace-sides-images'
import { adspaceToasts } from 'modules/adspace/constants/adspace-toasts.constants'
import { ALL_ADSPACES_QUERY } from 'modules/adspace/queries/adspace-queries'
import { AdspaceUpdateSchema, AdspaceUpdateSchemaType } from 'modules/adspace/schemas/adspace-update.schema'
import { getAdspaceSideLetter } from 'modules/adspace/utils/get-adspace-side-letter'
import { getAdspaceStorageFileName } from 'modules/adspace/utils/get-adspace-storage-file-name'
import { getAdspaceStringType } from 'modules/adspace/utils/get-adspace-string-type'
import { Fragment, memo, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { deleteImageFromStorage, uploadImageToStorage } from 'utils/firebase-utils'

interface AdspaceModalProps extends React.ComponentProps<typeof DialogTrigger> {
    adspace: AdspaceEntity
}
export const AdspaceModal: React.FC<AdspaceModalProps> = memo(({ adspace, ...props }) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false)

    const [updateAdspaceMutation] = useUpdateAdspaceMutation({
        refetchQueries: [ALL_ADSPACES_QUERY],
    })
    const form = useForm<AdspaceUpdateSchemaType>({
        resolver: zodResolver(AdspaceUpdateSchema),
        defaultValues: {
            ...adspace,
            sides: adspace.sides.map(side => ({
                ...side,
                deleteImage: false,
            })),
        },
    })

    const onClose = useCallback(() => {
        setOpen(false)
    }, [])

    const onLinkCopy = useCallback(() => {
        try {
            navigator.clipboard.writeText(urlConfig.WEBSITE_URL + urlConfig.website.adspaceId.replace(':id', String(adspace.id)))
            adspaceToasts.copyLink.success(adspace.number)
        } catch (error) {
            adspaceToasts.copyLink.error()
        }
    }, [adspace.id, adspace.number])

    const onSubmit = useCallback(
        async (values: AdspaceUpdateSchemaType) => {
            try {
                setLoading(true)

                // trim sides text
                values.sides = values.sides.map(side => ({
                    ...side,
                    text: side.text.trim(),
                }))

                // process images
                values.sides = await Promise.all(
                    values.sides.map(async (side, index) => {
                        const { file, deleteImage, ...rest } = side
                        // remove old image before uploading new one
                        if ((file && side.image) || deleteImage) {
                            await deleteImageFromStorage(side.image).catch(console.error)
                            return {
                                ...rest,
                                image: '',
                            }
                        }
                        // no file - do not upload file to storage
                        if (!file) {
                            return rest
                        }
                        const storageString = getAdspaceStorageFileName({
                            id: adspace.id,
                            type: values.type,
                            index,
                        })
                        const imageUrl = await uploadImageToStorage(file, storageString)
                        // write url to values.sides[index].image
                        return {
                            ...rest,
                            image: imageUrl,
                        }
                    }),
                )

                const res = await updateAdspaceMutation({
                    variables: {
                        data: {
                            id: adspace.id,
                            ...values,
                        },
                    },
                })

                if ((!!res.errors?.length && res.errors.length > 0) || !res.data?.updateAdspace.id) {
                    throw new Error(`Response error: ${JSON.stringify(res.errors)}`)
                }

                adspaceToasts.update.success(adspace.number)
                setOpen(false)
            } catch (error) {
                console.error(error)
                globalToasts.unknownError()
            } finally {
                setLoading(false)
            }
        },
        [adspace, updateAdspaceMutation],
    )

    useEffect(() => {
        // update default values on open
        if (open) {
            form.reset({
                ...adspace,
                sides: adspace.sides.map(side => ({
                    ...side,
                    deleteImage: false,
                })),
            })
        }
    }, [adspace, form, open])

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger {...props} />
            <DialogContent className="max-h-[95vh] min-w-[90%] scroll-m-0 overflow-y-scroll md:min-w-[90%] lg:min-w-[70%]">
                {/* header */}
                <DialogHeader>
                    <CardTitle>Площина {adspace.number}</CardTitle>
                    <CardDescription>{getAdspaceStringType(adspace.type)}</CardDescription>
                </DialogHeader>

                {/* images */}
                <AdspaceSidesImages adspace={adspace} />

                {/* edit */}
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                        <div className="h-px bg-none" />
                        <div className="bg-muted h-px" />
                        <div className="h-px bg-none" />

                        <CardTitle>Загальні налаштування</CardTitle>

                        <FormField
                            name="type"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Тип</FormLabel>
                                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select type" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            <SelectItem value={AdspaceTypeEnum.Arka}>Арка</SelectItem>
                                            <SelectItem value={AdspaceTypeEnum.Billboard}>Білборд</SelectItem>
                                            <SelectItem value={AdspaceTypeEnum.Oneside}>Односторонній</SelectItem>
                                            <SelectItem value={AdspaceTypeEnum.Duplex}>Дуплекс</SelectItem>
                                        </SelectContent>
                                    </Select>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            name="number"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Номер</FormLabel>
                                    <FormControl>
                                        <Input
                                            {...field}
                                            type="number"
                                            onChange={e => field.onChange(e.target.value.length > 0 && Number(e.target.value))}
                                            onWheel={e => {
                                                e.preventDefault()
                                                e.currentTarget.blur()
                                            }}
                                            className="number-input-no-arrows"
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            name="lat"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Широта</FormLabel>
                                    <FormControl>
                                        <Input
                                            {...field}
                                            type="number"
                                            onChange={e => field.onChange(e.target.value.length > 0 && Number(e.target.value))}
                                            onWheel={e => {
                                                e.preventDefault()
                                                e.currentTarget.blur()
                                            }}
                                            className="number-input-no-arrows"
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            name="lng"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Довгота</FormLabel>
                                    <FormControl>
                                        <Input
                                            {...field}
                                            type="number"
                                            onChange={e => field.onChange(e.target.value.length > 0 && Number(e.target.value))}
                                            onWheel={e => {
                                                e.preventDefault()
                                                e.currentTarget.blur()
                                            }}
                                            className="number-input-no-arrows"
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        {adspace.sides.map((_, index) => (
                            <Fragment key={index}>
                                <div className="h-px bg-none" />
                                <div className="bg-muted h-px" />
                                <div className="h-px bg-none" />

                                <CardTitle>Сторона {getAdspaceSideLetter(adspace.type, index) ?? '...'}</CardTitle>

                                {/* text */}
                                <FormField
                                    name={`sides.${index}.text`}
                                    control={form.control}
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Текст</FormLabel>
                                            <FormControl>
                                                <Input {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                {/* busyTo */}
                                <FormField
                                    name={`sides.${index}.busyTo`}
                                    control={form.control}
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Зайнята до</FormLabel>
                                            <div className="flex gap-1.5">
                                                <DatePicker
                                                    selected={field.value ? new Date(field.value) : undefined}
                                                    onSelect={value => field.onChange(value?.toISOString())}
                                                    disabled={date => date < new Date()}
                                                    className="w-full"
                                                />
                                                <Button
                                                    type="button"
                                                    variant="outline"
                                                    size="icon"
                                                    onClick={() => {
                                                        field.onChange('')
                                                    }}
                                                >
                                                    <Trash2 {...defaultIconProps} />
                                                </Button>
                                            </div>
                                            <FormDescription>Дата, до якої площина зайнята</FormDescription>

                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                {/* images only for first 2 sides */}
                                {index < 2 && (
                                    <>
                                        {/* image */}
                                        {/* <FormField
                                            name={`sides.${index}.image`}
                                            control={form.control}
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>Зображення (URL)</FormLabel>
                                                    <FormControl>
                                                        <Input {...field} />
                                                    </FormControl>
                                                    <FormDescription>Не редагувати, заповнюється автоматично</FormDescription>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        /> */}
                                        {/* file */}
                                        <FormField
                                            name={`sides.${index}.file`}
                                            control={form.control}
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>Зображення (файл)</FormLabel>
                                                    <div className="flex gap-1.5">
                                                        <FormControl>
                                                            <Input
                                                                type="file"
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                    field.onChange(e.target.files?.[0])
                                                                }
                                                            />
                                                        </FormControl>
                                                        <ImageToggle
                                                            variant="outline"
                                                            onClick={() =>
                                                                form.setValue(
                                                                    `sides.${index}.deleteImage`,
                                                                    !form.getValues(`sides.${index}.deleteImage`),
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <FormDescription>
                                                        Натисніть та виберіть зображення для відображення
                                                    </FormDescription>
                                                </FormItem>
                                            )}
                                        />
                                    </>
                                )}
                            </Fragment>
                        ))}

                        <div className="h-px bg-none" />

                        {/* footer */}
                        <DialogFooter className="flex gap-2">
                            <AdspaceDeleteConfirmable adspace={adspace} asChild>
                                <LoadingButton variant="destructive" disabled={loading} className="flex gap-2">
                                    <PackageMinus {...defaultIconProps} />
                                    Видалити
                                </LoadingButton>
                            </AdspaceDeleteConfirmable>
                            <LoadingButton
                                variant="outline"
                                type="button"
                                onClick={onLinkCopy}
                                disabled={loading}
                                className="flex gap-2"
                            >
                                <Link {...defaultIconProps} />
                                Посилання
                            </LoadingButton>
                            <LoadingButton variant="outline" type="button" onClick={onClose} disabled={loading}>
                                Скасувати
                            </LoadingButton>
                            <LoadingButton type="submit" loading={loading}>
                                Зберегти
                            </LoadingButton>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
})
AdspaceModal.displayName = AdspaceModal.name
